<template>
  <date-picker class="time-picker" mode="time" v-model="time" :is24hr="true">
    <template v-slot="{ togglePopover }">
      <div class="form-group" :class="customClass" @click="togglePopover()" @keyup.enter="togglePopover()">
        <span class="material-icons" aria-hidden="true">schedule</span>
        <input type="text" class="form-control icon-left" :placeholder="label" :value="getReadableTime(time)">
        <span v-if="time!==null" class="material-icons right" @click="deselectValue" aria-hidden="true">close</span>
      </div>
    </template>
  </date-picker>
</template>

<script>
  import { DatePicker } from 'v-calendar';
  import { getReadableTime } from '@/utils/appointment-helpers';

  export default {
    name: 'TimePicker',
    components: {
      DatePicker
    },
    props: {
      label: {
        type: String,
        default: "Uhrzeit"
      },
      customClass: {
        type: String,
        default: "mb10"
      },
      initialValue: {
        type: Date,
        default: null
      },
    },
    data() {
      return {
        time: null
      }
    },
    watch: {
      initialValue(value){
        this.time=value;
      },
      time(newTime) {
        this.$emit('changedTime',newTime);
      }
    },
    created() {
      this.time=this.initialValue;
    },
    computed:{
    },
    methods: {
      getReadableTime,
      deselectValue(){
        this.time=null;
        this.$emit('changedTime',null);
      }
    }
  }
</script>
<style lang="scss">

  .time-picker{
    .vc-date{
      display: none;
    }

    .material-icons{
      cursor:pointer!important;
    }

    .right{
      right: 0;
    }
  }

</style>
